import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Navigate, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import DmgQRCode from "../../../components/QRCode.js";
import { ArrowRightCircle, Check, DollarSign, Image } from "react-feather";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Stepper,
  Step,
  StepLabel,
  Tabs,
  Tab,
  Paper,
  ImageList,
  ImageListItem,
  CircularProgress,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";

import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";

// Quill imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

// Calendar imports
import calendarStyle from "../../pages/Calendar.style";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import EvaluationDetail from "./EvaluationDetail";
import Progress from "./Progress.js";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const ProductImg = styled.img`
  display: inline-block;
  height: auto;
  width: 128px;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const steps = [
  "RMA Requested",
  "RMA Approved",
  "Equipment Received",
  "Evaluation Complete",
  "Service Approved",
  "Service Complete",
  "Equipment Returned",
];

export function Product(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Product
        </Typography>

        <Spacer mb={2} />

        <Centered>
          <ProductImg alt="Arrow 100" src={props.productImage} />
          <Typography variant="h4" component="div" gutterBottom>
            <Box fontWeight="fontWeightBold">{props.product}</Box>
            <Box fontWeight="fontWeightLight">{props.serialNumber}</Box>
          </Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}

export function Location(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Location
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Typography>
            {props.location ? props.location : "No location assigned."}
          </Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}
export function ScanToOpen(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Scan to open on another device
        </Typography>

        <Spacer mb={4} />
        <Centered>
          <DmgQRCode
            link={"https://support.gps-mapping.com/ticket/" + props.id + "/"}
          />
        </Centered>
      </CardContent>
    </Card>
  );
}

export function Contact(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Contact
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Typography>{props.contactName}</Typography>
          <Typography>{props.companyName}</Typography>
          <Typography>{props.contactEmail}</Typography>
          <Typography>{props.contactPhone}</Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}

function ReturnAddress(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Return Address
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography>{props.company}</Typography>
            <Typography>{props.name}</Typography>
            <Typography>{props.address}</Typography>
            <Typography>{props.address2}</Typography>
            <Typography>
              {props.city}, {props.state} {props.zip}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography>{props.phone}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function Billing(props) {
  const [estimate_num, setEstimateNum] = React.useState("");
  useEffect(() => {
    if (props.evaluation) {
      axios.get(props.evaluation).then((response) => {
        setEstimateNum(response.data.estimate_num);
      });
    }
  }, []);
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Billing
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Typography>
            Estimate #: {estimate_num ? estimate_num : "None"}
          </Typography>
          <Typography>
            Invoice #: {props.invoice ? props.invoice : "None"}
          </Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}

function ServiceLength(props) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {props.ticket.service_duration_days === undefined && (
              <Box fontWeight="fontWeightRegular">-- days</Box>
            )}
            {props.ticket.service_duration_days === 0 && (
              <Box fontWeight="fontWeightRegular">0 days</Box>
            )}
            {props.ticket.service_duration_days === 1 && (
              <Box fontWeight="fontWeightRegular">
                {props.ticket.service_duration_days} day
              </Box>
            )}
            {props.ticket.service_duration_days > 1 && (
              <Box fontWeight="fontWeightRegular">
                {props.ticket.service_duration_days} days
              </Box>
            )}
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Service duration
          </Typography>

          {props.ticket.service_duration_days === undefined && (
            <LinearProgress
              variant="determinate"
              value={0}
              color="success"
              mt={4}
            />
          )}
          {props.ticket.service_duration_days >= 0 &&
            props.ticket.service_duration_days < 5 && (
              <LinearProgress
                variant="determinate"
                value={(props.ticket.service_duration_days / 7) * 100}
                color="success"
                mt={4}
              />
            )}
          {props.ticket.service_duration_days > 5 &&
            props.ticket.service_duration_days < 7 && (
              <LinearProgress
                variant="determinate"
                value={(props.ticket.service_duration_days / 7) * 100}
                color="warning"
                mt={4}
              />
            )}
          {props.ticket.service_duration_days > 7 && (
            <LinearProgress
              variant="determinate"
              value={(props.ticket.service_duration_days / 7) * 100}
              color="error"
              mt={4}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

function WorkTicket(props) {
  const navigate = useNavigate();

  function WorkBox(props) {
    return (
      <Box position="relative">
        <Card
          mb={6}
          pt={2}
          onClick={() =>
            navigate("/ticket/" + props.ticket.id + "/" + props.action)
          }
          style={{ cursor: "pointer" }}
        >
          <CardContent>
            <Typography variant="h4" gutterBottom>
              <Box fontWeight="fontWeightRegular">{props.title}</Box>
            </Typography>
            <Typography variant="body2" gutterBottom mt={3} mb={0}>
              Click here to progress the ticket
            </Typography>

            <StatsIcon>
              <ArrowRightCircle />
            </StatsIcon>
            <LinearProgress
              variant="determinate"
              value={((props.ticket.status * 100) / 700) * 100}
              color="success"
              mt={4}
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  function CompletedBox(props) {
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              <Box fontWeight="fontWeightRegular">Completed!</Box>
            </Typography>
            <Typography variant="body2" gutterBottom mt={3} mb={0}>
              Ticket resolved.
            </Typography>

            <StatsIcon>
              <Check style={{ color: "green" }} />
            </StatsIcon>
            <LinearProgress
              variant="determinate"
              value={((props.ticket.status * 100) / 700) * 100}
              color="primary"
              mt={4}
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  switch (props.ticket.status) {
    // Ticket requested
    case 0:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Approve Ticket"
          action="approve"
        />
      );
    // Ticket approved
    case 1:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Check-In Equipment"
          action="receive"
        />
      );
    // Ticket Received
    case 2:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Start Evaluation"
          action="evaluate"
        />
      );
    // Ticket Evaluated
    case 3:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Approve Service"
          action="approveservice"
        />
      );
    // Service approved
    case 4:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Complete Service"
          action="completeservice"
        />
      );
    // Service Complete
    case 5:
      return (
        <WorkBox
          ticket={props.ticket}
          title="Return Equipment"
          action="return"
        />
      );
    // Equipment returned
    case 6:
      return <WorkBox ticket={props.ticket} title="Resolve" action="resolve" />;
    // Resolved
    case 7:
      return <CompletedBox ticket={props.ticket} />;
  }
}

function Events(props) {
  const [isLoading, setLoading] = React.useState(true);
  const [history, setHistory] = useState({});

  useEffect(() => {
    axios
      .get(apiurl + "/history/" + props.ticket.id + "/")
      .then((response) => {
        setHistory(response.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.ticket]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (history.length > 0) {
    return (
      <React.Fragment>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((event, index) => (
                <TableRow key={index}>
                  <TableCell>{event.type}</TableCell>
                  <TableCell>{event.date}</TableCell>
                  <TableCell component="th" scope="row">
                    {event.user}
                  </TableCell>
                  <TableCell>
                    <ProductsChip
                      size="small"
                      label={event.status}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </React.Fragment>
    );
  } else {
    return <React.Fragment>No history.</React.Fragment>;
  }
}

function RMACalendar(props) {
  const [isLoading, setLoading] = React.useState(true);
  const [events] = useState([]);

  useEffect(() => {
    axios
      .get(apiurl + "/history/" + props.ticket.id + "/")
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          events.push({
            title: response.data[i].status,
            start: response.data[i].date,
          });
        }
      })
      .then((reponse) => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.ticket]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (events.length > 0) {
    return (
      <React.Fragment>
        <Calendar events={events} />
      </React.Fragment>
    );
  } else {
    return <React.Fragment>No history.</React.Fragment>;
  }
}

function EmptyCard(props) {
  return (
    <Card mb={6}>
      <CardContent p={6}>
        <FullCalendarWrapper>
          <FullCalendar
            initialView="dayGridMonth"
            initialDate={props.events[0].start}
            plugins={[dayGridPlugin, interactionPlugin]}
            events={props.events}
            editable={true}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridDay,dayGridWeek,dayGridMonth",
            }}
          />
        </FullCalendarWrapper>
      </CardContent>
    </Card>
  );
}

function Calendar(props) {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard events={props.events} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Events" {...a11yProps(0)} />
          <Tab label="Description" {...a11yProps(1)} />
          <Tab label="Evaluation" {...a11yProps(2)} />
          <Tab label="Shipments" {...a11yProps(3)} />
          <Tab label="Calendar" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Events ticket={props.ticket} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <QuillWrapper>
          <ReactQuill
            toolbar
            readOnly
            theme="snow"
            modules={{ toolbar: false }}
            fullWidth
            value={props.ticket.description}
            placeholder="Loading.."
          />
        </QuillWrapper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EvaluationDetail ticket={props.ticket} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Shipment url={props.ticket.equipment_received} />
        <Shipment url={props.ticket.equipment_returned} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <RMACalendar ticket={props.ticket} />
      </TabPanel>
    </Box>
  );
}

function Issues(props) {
  const [isLoading, setLoading] = React.useState(true);
  const [issues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        for (let i = 0; i < props.issues.length; i++) {
          const { data: response } = await axios.get(props.issues);
          console.log(response);
          issues.push(response);
        }
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
      console.log("Printing issues in fetched array:");
      console.log(issues);
    };

    fetchData();
  }, [props.issues]);

  if (issues.length < 1) {
    return <span>No issues found.</span>;
  } else {
    return (
      <React.Fragment>
        {isLoading && <div>Loading...</div>}
        {!isLoading && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Issue</TableCell>
                  <TableCell align="left">Resolution</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issues.map((issue, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {issue.description}
                    </TableCell>
                    <TableCell align="left">{issue.resolution}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </React.Fragment>
    );
  }
}

function StandardImageList(props) {
  if (props.images.length === 0) {
    return (
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
        }}
      >
        <Image />
        <span>No images.</span>
      </div>
    );
  } else {
    return (
      <div>
        {props.images.length && (
          <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
            {props.images.map((image, index) => (
              <a href={image} target="_blank" rel="noreferrer">
                <ImageListItem key={index}>
                  <img
                    src={image}
                    srcSet={image}
                    alt={"shipment-" + index}
                    loading="lazy"
                  />
                </ImageListItem>
              </a>
            ))}
          </ImageList>
        )}
      </div>
    );
  }
}

function Shipment(props) {
  const [isLoading, setLoading] = React.useState(true);
  const [shipment, setShipment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(props.url);
        console.log(response);
        setShipment(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [props.url]);

  if (!shipment) {
    return <div></div>;
  } else {
    return (
      <React.Fragment>
        {isLoading && <div>Loading...</div>}
        {!isLoading && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Carrier</TableCell>
                    <TableCell align="left">Tracking #</TableCell>
                    <TableCell align="left">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={shipment.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{shipment.type}</TableCell>
                    <TableCell align="left">{shipment.carrier}</TableCell>
                    <TableCell align="left">
                      <a
                        href={shipment.tracking_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {shipment.tracking_number}
                      </a>
                    </TableCell>
                    <TableCell align="left">{shipment.date}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <StandardImageList images={shipment.images} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

function TicketDetail() {
  const [isLoading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    axios
      .get(apiurl + "/tickets/" + id + "/")
      .then((response) => {
        console.log(response.data);
        setTicket(response.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active": {},
      "& .Mui-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
      "& .MuiStepIcon-text": { color: "black" },
    },
  }));

  const c = useStyles();

  return (
    <React.Fragment>
      <Helmet title="RMA Status" />

      <Typography variant="h3" gutterBottom display="inline">
        RMA# {ticket.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/tickets">
          Tickets
        </Link>
        <Typography>Ticket Detail</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {isLoading && <CircularProgress />}

      {!isLoading && (
        <div>
          <Progress step={ticket.status + 1}></Progress>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4} xl={3}>
              <Product
                product={ticket.product_name}
                productImage={ticket.product_image}
                serialNumber={ticket.serial_number}
              />
              <Location location={ticket.location} />
              <ScanToOpen id={ticket.id} />
              <Contact
                contactName={ticket.contact_name}
                companyName={ticket.contact_company}
                contactEmail={ticket.contact_email}
                contactPhone={ticket.contact_phone}
              />
              <ReturnAddress
                company={ticket.contact_company}
                name={ticket.return_attention}
                address={ticket.return_address}
                address2={ticket.return_address2}
                city={ticket.return_city}
                state={ticket.return_state}
                zip={ticket.return_zip}
                phone={ticket.contact_phone}
              />
              <Billing
                invoice={ticket.invoice}
                evaluation={ticket.evaluation}
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={4}>
                  <WorkTicket ticket={ticket} />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ServiceLength ticket={ticket} />
                </Grid>
              </Grid>
              <BasicTabs ticket={ticket} />
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

export default TicketDetail;
